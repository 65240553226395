// @flow
import React from 'react'
import { useBlock } from '@shift-marketing/shift-one-website-editor'

import logo from 'pages/Landing/images/logo.svg'
import LandingPageFeatureImage from 'components/LandingPageFeatureImage'

import styles from './styles.module.scss'
import propertiesSchema from './properties.schema.json'

export default function Sidebar () {
    const { properties, ref } = useBlock('landing.featuredImage', propertiesSchema)

    return (
        <aside ref={ref} className={styles.leftSide} style={{ backgroundColor: properties.backgroundColor }}>
            <div className={styles.logo}>
                <img src={logo} alt="Logo"/>
            </div>

            <LandingPageFeatureImage/>
        </aside>
    )
}
