// @flow
import React from 'react'
import { EditableContent, useBlock } from '@shift-marketing/shift-one-website-editor'

import propertiesSchema from './properties.schema.json'

import styles from './styles.module.scss'

type Props = {
    status: null | 'loading' | 'success',
}

export default function DownloadButton (props: Props) {
    const { properties, editMode, ref, handlePropertyChange } = useBlock('download.button', propertiesSchema)
    const [icon, setIcon] = React.useState(null)

    React.useEffect(() => {
        import(`./images/icon/${properties.icon}.svg`)
            .then(module => {
                setIcon(module.default)
            })
    }, [properties.icon])

    return (
        <button className={styles.button} disabled={props.status !== null} ref={ref} style={properties.style}>
            {props.status === 'loading' ? (
                properties.label + '...'
            ) : (props.status === 'success' ? (
                'Success'
                ) : (
                    <EditableContent
                        editMode={editMode}
                        content={properties.label}
                        property="label"
                        onChange={handlePropertyChange}
                        inline
                        className={styles.label}
                    />
                )
            )}
            <div style={{
                backgroundImage: `url(${icon})`
            }} className={styles.icon}/>
        </button>
    )
}
