// @flow
import React from 'react'
import { TextBlock } from '@shift-marketing/shift-one-website-editor'

import withTheme from 'hoc/withTheme'

import { Store } from 'Store'

import Sidebar from 'components/Sidebar'

import styles from './styles.module.scss'

import { withTranslation } from 'react-i18next'
import transDomain from './translations/index.translations'

import themes, { type Theme } from './themes/__supportedThemes'

import LoginForm from 'components/LoginForm'

type Props = {
    history: any,
    location: any,
    match: any,
    theme: Theme,
    t: any,
};

function Landing (props: Props) {
    const { state } = React.useContext(Store)
    const { t } = props

    return (
        <div className={styles.container}>
            <Sidebar/>

            <div className={styles.rightSide}>
                <div className={styles.rightSideInner}>
                    <div className={styles.content}>
                        <TextBlock
                            blockId={'landing.topMessage'}
                            defaultContent={`<h2>Download your product sheet today<br /><strong>(it's free!).</strong></h2>`}
                            locale={state.locale}
                            values={{}}
                        />

                        <LoginForm/>

                        <TextBlock
                            blockId={'landing.bottomMessage'}
                            defaultContent={`
                            <h3>What's inside:</h3>
                            <p>Amazing new features</p>
                            <p>Top tier support</p>
                            <p>Many more...</p>
                        `}
                            locale={state.locale}
                            values={{}}
                        />
                    </div>
                </div>

                <footer className={styles.footer}>
                    <span>
                        {t('footer.line1')}
                    </span>
                    <span>
                        {t('footer.line2')}
                    </span>
                </footer>
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(themes)(Landing))
