// @flow
import React from 'react'
import { ImageBlock } from '@shift-marketing/shift-one-website-editor'
import { Store } from 'Store'

import VideoPlayButton from 'components/VideoPlayButton'

import styles from './styles.module.scss'

import image from './placeholder.png'

export default function LandingPageFeatureImage () {
    const { state: { campaign } } = React.useContext(Store)
    // const { properties, ref } = useBlock('landing.featured', propertiesSchema)

    return (
        <div className={styles.image}>
            <ImageBlock blockId="landing.featured.image" defaultImage={image} title="Featured image"/>

            {campaign.videos?.sidebar && (
                <div className={styles.button}>
                    <VideoPlayButton videoHref={campaign.videos?.sidebar}/>
                </div>
            )}
        </div>
    )
}
