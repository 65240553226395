//@flow
import React from 'react'
import { useBlock } from '@shift-marketing/shift-one-website-editor'

import { fetchLead, patchLead } from 'Actions'
import { Store } from 'Store'

import DownloadButton from 'components/DownloadButton'
import styles from 'components/LoginForm/styles.module.scss'
import schema from './properties.schema.json'

export default function () {
    const { properties, ref } = useBlock('loginForm', schema)
    const { dispatch } = React.useContext(Store)
    const [formState, setFormState] = React.useState(null)

    const handleSubmit = React.useCallback(async e => {
        e.preventDefault()
        setFormState('loading')

        try {
            let code = e.target.elements['code'].value
            await fetchLead(dispatch, code)
            const lead = await patchLead(dispatch, code, {}, true)

            if (lead.incentives.downloadable?.filename) {
                const response = await fetch(lead.incentives.downloadable.url)
                if (!response.ok) {
                    console.error(await response.text())
                    alert('Unable to download playbook.')
                    return
                }

                const blob = await response.blob()

                const a = document.createElement('a')
                a.href = window.URL.createObjectURL(blob)
                a.download = lead.incentives.downloadable.filename
                document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click()
                a.remove() // afterwards we remove the element again
            } else {
                window.location = lead.incentives.downloadable.url
            }

            setFormState('success')
        } catch (err) {
            console.error(err)
            setFormState(null)
        }
    }, [dispatch])

    return (
        <form className={styles.form} onSubmit={handleSubmit} ref={ref}>
            <input
                name="code"
                type={properties.showCodeField ? 'text' : 'hidden'}
                placeholder="Enter your code"
                defaultValue={(new URLSearchParams(window.location.hash.substring(1))).get('code')}
            />

            <DownloadButton status={formState}/>
        </form>

    )
}
